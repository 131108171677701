ion-modal.modal-notification {
  &::part(content) {
    @apply p-0 mx-5 max-w-90  md:mx-auto rounded-xl #{!important};
  }
  --height: auto;
  .ion-page {
    position: relative !important;
    display: block;
    contain: content;
    .inner-content {
      max-height: 80vh;
      overflow: auto;
    }
  }
}

ion-modal.modal-inactive-service {
  &::part(content) {
    max-width: 311px;
    max-height: 372px;
    @apply rounded-xl p-0 mx-5 md:mx-auto #{!important};
  }
  .ion-page {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: contents;
    position: initial;
    z-index: 0;
  }
}

ion-modal.modal-notification-fullscreen {
  &::part(content) {
    min-height: 100vh;
    min-width: 100vw;
    @apply p-0 mx-5 md:mx-auto #{!important};
  }
}

ion-modal.modal-password-save-changes {
  &::part(content) {
    @apply md:rounded-xl lg:w-[532px] lg:h-[800px] #{!important};
  }
}

ion-modal.modal-refer-help {
  @apply pt-20 pb-15;
  @screen sm {
    @apply p-3;
  }
  @screen md {
    @apply p-0;
  }
  &::part(content) {
    border-radius: 12px;
    @apply m-4;
    @screen md {
      @apply m-0;
      width: 375px !important;
      --height: 80%;
    }
  }
}

//modal type alert
ion-modal.modal-dialog {
  &::part(content) {
    @apply p-0 mx-5 max-w-90 md:mx-auto rounded-xl #{!important};
    @screen md {
      width: 311px !important;
    }
  }
  --height: auto;
  .ion-page {
    position: relative !important;
    display: block;
    contain: content;
    .inner-content {
      max-height: 80vh;
      overflow: auto;
    }
  }
}

ion-modal.modal-scan-code {
  --width: 750px;

  --ion-safe-area-top: 0px;
  --ion-safe-area-bottom: 0px;
  --ion-safe-area-right: 0px;
  --ion-safe-area-left: 0px;
  --border-radius: 12px !important;

  &::part(content) {
    @apply p-0 mx-5 md:w-2/5 lg:max-w-120 md:mx-auto rounded-xl #{!important};
    height: 343px !important;
    ion-content {
      height: 233px !important;
    }
    @screen md {
      height: 472px !important;
      ion-content {
        height: 360px !important;
      }
      width: 750px !important;
    }
    .ion-page {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: contents;
      position: initial;
      z-index: 0;
    }
  }
}

ion-modal.modal-order-detail {
  &::part(content) {
    @apply p-0 mx-5 max-w-[476px] md:mx-auto rounded-xl #{!important};
    @screen md {
      width: calc(100vw - 48px) !important;
    }
  }
  --height: auto;
  .ion-page {
    position: relative !important;
    display: block;
    contain: content;
    .inner-content {
      max-height: 80vh;
      overflow: auto;
    }
  }
}

ion-modal.modal-cancel-transaction {
  --max-width: 311px !important;
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

ion-modal.modal-wrapper {
  --width: fit-content;
  --min-width: 250px;
  --max-width: 311px;
  --max-height: 80vh;
  --height: fit-content;
  --border-radius: 12px;
  @screen md {
    --max-width: 360px;
  }

  .ion-page {
    @apply flex flex-col max-h-[80vh];
    h4 {
      @apply text-center font-semibold;
    }
    ion-footer {
      @apply flex flex-col;
      ion-button {
        @apply max-w-[240px] mx-auto;
      }
    }
    .wrapper-modal-content {
      @apply flex flex-col gap-3.5 px-3.5;
    }
    .wrapper-modal-icon {
      @apply rounded-full mx-auto w-[120px] h-[120px] bg-primary p-4;
    }
    ion-content {
      @apply flex-1 overflow-y-auto;
      contain: inherit;
      &::part(scroll) {
        position: relative;
      }
    }
  }
}


app-congrats-modal {
  justify-content: center !important;
  position: fixed !important;
}

.update-password-modal {
  &::part(content) {
    border-radius: 12px;
    height: 452px !important;
    width: 300px !important;
  }

  .modal-content {
    padding: 0px 20px;
    overflow-y: scroll;

    h3 {
      margin-bottom: 0px !important;
      font-size: 17px !important;
      color: var(--ion-color-dark) !important;
    }

    p {
      font-weight: normal;
      font-size: 13px !important;
      margin: 0px !important;
    }
  }

  img {
    width: 30vh;
  }
}

.congrats-modal {
  &::part(content) {
    border-radius: 12px;
    height: 452px !important;
    width: 300px !important;
  }

  .modal-content {
    padding: 0px 20px;
    overflow-y: scroll;

    h3 {
      margin-bottom: 0px !important;
      font-size: 17px !important;
      color: var(--ion-color-dark) !important;
    }

    p {
      font-weight: normal;
      font-size: 13px !important;
      margin: 0px !important;
    }
  }

  img {
    width: 30vh;
  }
}

@media (min-width: 788px) and (max-width: 789px) {
  .update-password-modal {
    &::part(content) {
      height: 480px !important;
      --width: 0px !important;
      --height: 0px !important;
    }
  }
}

@media (min-width: 792px) and (max-width: 793px) {
  .update-password-modal {
    &::part(content) {
      height: 500px !important;
      width: 340px !important;
      --width: 0px !important;
      --height: 0px !important;
    }
  }
}

@media (min-width: 972px) {
  .update-password-modal {
    &::part(content) {
      border-radius: 12px;
      height: 500px !important;
      width: 360px !important;
    }
  }
}
